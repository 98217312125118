import http from "@/http";

class DivisaoService {
  private endpoint = "divisoes";

  list(): Promise<any> {
    return http.get(`${this.endpoint}`);
  }

  find(id: string): Promise<any> {
    return http.get(`${this.endpoint}/${id}`);
  }

  getCursos(id: string): Promise<any> {
    return http.get(`${this.endpoint}/cursos/${id}`);
  }

  getPlanos(id: string): Promise<any> {
    return http.get(`${this.endpoint}/planos/${id}`);
  }

  getTurmas(id: string): Promise<any> {
    return http.get(`${this.endpoint}/turmas/${id}`);
  }

  getDisciplinas(id: string): Promise<any> {
    return http.get(`${this.endpoint}/disciplinas/${id}`);
  }

  getDisciplinasSelect(id: string): Promise<any> {
    return http.get(`${this.endpoint}/disciplinas/list-select/${id}`);
  }

  getDisciplinasSelectRedacao(id: string): Promise<any> {
    return http.get(`${this.endpoint}/disciplinas/list-select-redacao/${id}`);
  }

  minhasDivisoes(): Promise<any> {
    return http.get(`aluno/divisoes`);
  }

  getDisciplinasByCurso(divisaoId: string, cursoId: string): Promise<any> {
    return http.get(
      `${this.endpoint}/disciplinas-by-curso/${divisaoId}/${cursoId}`
    );
  }
}

export default new DivisaoService();
